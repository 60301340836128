import React from 'react'
import PropTypes from 'prop-types'
import DraggableTable from '../DraggableTable'
import StudentProgramsStatusFilter from './StudentProgramsStatusFilter'
import StudentProgramsItemActivities from './StudentProgramsItemActivities'
import LinkDestroy from '../link/LinkDestroy'
import $ from 'jquery-migrate'
import Modal from '../Modal'
import { isEqual } from 'lodash'

const StudentProgramsList = (props) => {
  const DEFAULT_MODAL_BODY =
    'You are about to remove this program from this Training Plan.\
                            Are you sure you want to do this?'
  const FORCE_MODAL_BODY =
    'You are about to manually update activities assigned via a\
                            Global Program. This change would disconnect those activities\
                            from that Global Program. This cannot be undone. Are you sure\
                            you want to disconnect these activities from their Global Program?'

  const SWAP_MODAL_BODY =
    'You are about to swap this Activity.\
    This will swap this activity out for all current and future sessions.\
    Are you sure you want to do this?'

  const currStudentPrograms = JSON.parse(props.studentPrograms) || []
  const [studentPrograms, setStudentPrograms] = React.useState(
    JSON.parse(props.studentPrograms) || []
  )
  const [completedStudentPrograms] = React.useState(
    studentPrograms
      .filter((el) => el.status == 'Completed')
      .sort((a, b) => b.position - a.position)
  )

  const [currentAvailableSpas, setCurrentAvailableSpas] = React.useState(
    JSON.parse(props.current_available_spas).sort((a, b) => {
      return a.position - b.position
    }) || []
  )

  const [availableSwapSpas, setAvailableSwapSpas] = React.useState(
    JSON.parse(props.available_swap_spas) || []
  )

  const [showModal, setShowModal] = React.useState(false)
  const [modalCallback, setModalCallback] = React.useState(null)
  const [modalBody, setModalBody] = React.useState(null)
  const [modalActions, setModalActions] = React.useState(null)
  const [completedStatusTab, setCompletedStatusTab] = React.useState(false)
  const [showActivities, setShowActivities] = React.useState(false)

  const [submitButton, setSubmitButton] = React.useState(React.createRef())

  const upcomingStudentPrograms = studentPrograms.filter(
    (el) => el.status !== 'Completed'
  )

  const completedStatusStudentPrograms = completedStatusTab
    ? completedStudentPrograms
    : upcomingStudentPrograms

  const onReorder = (startIndex, endIndex) => {
    const result = [...completedStatusStudentPrograms]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    setStudentPrograms(result)
  }

  const getSpasFromCurrentAvailableSpas = (program_id) => {
    return currentAvailableSpas.filter((spas) => spas.program_id === program_id)
  }

  const programsChanged = !isEqual(
    currStudentPrograms
      .filter((el) => el.status !== 'Completed')
      .map((pr) => pr.id),
    studentPrograms.filter((el) => el.status !== 'Completed').map((pr) => pr.id)
  )

  const sendRequest = (options) => {
    $.ajax({
      url: options.path,
      method: options.method,
      dataType: 'JSON',
      data: options.params,
      cache: false,
      success: options.success,
      error: options.error
    })
  }

  const onSubmitClick = (e) => {
    e.preventDefault()
    if (
      studentPrograms.some((element, index, array) => element.active_template)
    ) {
      setModalBody(FORCE_MODAL_BODY)
      setModalActions([
        { title: 'Cancel', callback: () => setShowModal(false) },
        { title: 'Confirm', callback: () => submitButton.click() }
      ])
      setShowModal(true)
    } else {
      submitButton.click()
    }
  }

  const onSwapClick = (e, spas, student_program_id) => {
    // e.preventDefault();
    setModalBody(SWAP_MODAL_BODY)
    setModalActions([
      { title: 'Cancel', callback: () => setShowModal(false) },
      {
        title: 'Confirm',
        callback: () => {
          sendRequest({
            path: props.collectionPath + '/swap_state',
            method: 'PATCH',
            params: {
              id: student_program_id,
              spas_id: spas.id
            },
            success: () => window.location.reload(),
            error: (e) => alert(e)
          })
        }
      }
    ])
    setShowModal(true)
  }

  const onDeleteClick = (e, item) => {
    e.preventDefault()
    if (item.active_template) {
      setModalBody(FORCE_MODAL_BODY)
      setModalActions([
        { title: 'Cancel', callback: () => setShowModal(false) },
        {
          title: 'Confirm',
          callback: () =>
            sendRequest({
              path: props.collectionPath + '/' + item.id,
              method: 'DELETE',
              params: { force: true },
              success: () => window.location.reload(),
              error: (e) => alert(e)
            })
        }
      ])
    } else {
      setModalBody(DEFAULT_MODAL_BODY)
      setModalActions([
        { title: 'Cancel', callback: () => setShowModal(false) },
        {
          title: 'Confirm',
          callback: () =>
            sendRequest({
              path: props.collectionPath + '/' + item.id,
              method: 'DELETE',
              params: {},
              success: () => window.location.reload(),
              error: (e) => alert(e)
            })
        }
      ])
    }
    setShowModal(true)
  }

  const serializeItems = completedStatusStudentPrograms.map((item, ix) => ({
    id: `student-program-${ix}`,
    contents: (
      <React.Fragment>
        <td>
          {props.programsPath ? (
            <a
              href={props.programsPath + '/' + item.program_id}
              title={`Position: ${item.position}\nClass: StudentProgram`}
            >
              {item.program_name}
            </a>
          ) : (
            item.program_name
          )}
          {item.can_be_updated && (
            <input
              type="hidden"
              value={item.id}
              name="student[student_programs_attributes][][id]"
            />
          )}
          {item.can_be_updated && (
            <input
              id={`student_program_${item.id}_position`}
              type="hidden"
              value={ix}
              name="student[student_programs_attributes][][position]"
            />
          )}
        </td>
        <td>
          {item.program_description}
          {showActivities && (
            <ul>
              {getSpasFromCurrentAvailableSpas(item.program_id).map(
                (spas, index) => {
                  return (
                    <li key={index}>
                      {!completedStatusTab && (
                        <i
                          rel="nofollow"
                          href="#"
                          onClick={(e) => onSwapClick(e, spas, item.id)}
                          style={{
                            lineHeight: 0,
                            padding: '5px 5px',
                            margin: 2
                          }}
                          className="btn btn-primary btn-sm action-button"
                          title="Add as Swap Activity"
                        >
                          <span
                            className="fa fa-flag-checkered"
                            aria-hidden="true"
                          />
                        </i>
                      )}
                      <span
                        title={`Swapped In: ${spas?.activity.swapped_in}\nSpas Position: ${spas?.activity.position}`}
                      >
                        {spas.activity.name}
                      </span>
                    </li>
                  )
                }
              )}
            </ul>
          )}
        </td>
        <td>
          {props.globalProgramsPath && item.active_template ? (
            <a href={props.globalProgramsPath + '/' + item.queue_template_id}>
              {item.global_program_name}
            </a>
          ) : null}
          {!props.globalProgramsPath && item.active_template
            ? item.global_program_name
            : null}
        </td>
        <td>{item.status}</td>
        <td>
          {(item.status !== 'Completed' &&
            props.globalProgramsPath &&
            item.status) ||
            (props.globalProgramsPath && (
              <a href={'reflections/' + item.reflection_id}>
                {' '}
                {item.reflection_name}{' '}
              </a>
            ))}
        </td>
        <td id={`student_program_${item.id}_delete`}>
          {item.can_be_updated && (
            <a
              className="action-button space-right"
              rel="nofollow"
              href="#"
              onClick={(e) => onDeleteClick(e, item)}
              title="Remove Program from Training Plan"
            >
              <span className="fa fa-trash-o" style={{ color: '#555' }} />
            </a>
          )}
        </td>
      </React.Fragment>
    )
  }))

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row bottom_space">
            <StudentProgramsStatusFilter
              completedStatusTab={completedStatusTab}
              setCompletedStatusTab={setCompletedStatusTab}
            />
            <div className="col-md-3 col-sm-12 grid_space pull-right">
              <button
                type="button"
                className="btn btn-default btn-block text-center"
                onClick={() => setShowActivities(!showActivities)}
              >
                {showActivities ? 'Hide' : 'Show'} Activities
              </button>
            </div>
          </div>
          {!completedStatusTab && (
            <>
              <div
                className="col-md-6"
                style={{ paddingLeft: 'unset', left: -15 }}
              >
                {programsChanged && (
                  <div style={{ fontWeight: 'bold', color: 'red' }}>
                    To apply your changes, press the SUBMIT button.
                  </div>
                )}
              </div>
              <div className="col-md-6" style={{ paddingRight: 'unset' }}>
                <button
                  type="button"
                  className="btn btn-primary pull-right small_bottom_space"
                  onClick={(e) => onSubmitClick(e)}
                  disabled={!programsChanged}
                >
                  Submit
                </button>
                <button
                  style={{ display: 'none' }}
                  ref={(ref) => setSubmitButton(ref)}
                >
                  Submit
                </button>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-md-12">
              <table
                className="table table-condensed table-striped w-100 d-block d-md-table"
                id="student_programs_list"
              >
                <thead>
                  <tr>
                    <th>Program</th>
                    <th>Program Description</th>
                    <th>Global Program</th>
                    <th>Status</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <DraggableTable
                  items={serializeItems}
                  onReorder={onReorder}
                  isDragDisabled={completedStatusTab}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        actions={modalActions}
        body={modalBody}
        onCancel={() => setShowModal(false)}
        title="Are you sure?"
      />
    </React.Fragment>
  )
}

StudentProgramsList.propTypes = {
  collectionPath: PropTypes.string.isRequired,
  programsPath: PropTypes.string
}

export default StudentProgramsList
